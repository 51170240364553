import {
  CurrencyExchangeRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../controls";
import { Alert, Dialog, Snackbar, Table } from "../../../layout";
import AddEditPay from "./AddEditPay";
import ViewPayment from "./ViewPayment";
import { connect } from "react-redux";
import * as actions from "../../../api/actions/actions";
import { AppContext } from "../../../App";
import { controller, endpoints } from "../../../api/actions/api";
import { DropLi } from "../../../hooks";
import RevForm from "./RevForm";

const Payments = (props) => {
  const { _getById, _hoa, unit } = props;
  const { PayType } = DropLi();
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const [showForm, setShowForm] = useState(false);
  const [viewReq, setViewReq] = useState(false);
  const [viewData, setViewData] = useState();
  const [updData, setUpdData] = useState();
  const [pays, setPays] = useState([]);
  const [showRev, setShowRev] = useState(false);
  const { setLoader } = useContext(AppContext);
  const [snackbar, setSnackbar] = useState({
    show: false,
    type: "success",
    msg: "",
  });

  useEffect(() => {
    const onComplete = (res) => {
      setLoader(false);
      res.status === 200 && setPays([...res.result]);
    };
    if (_hoa.id) {
      unit
        ? _getById(
            endpoints.Payment + controller.GetAllByUnit,
            unit.id,
            null,
            onComplete
          )
        : _getById(
            endpoints.Payment + controller.GetByHoa,
            _hoa.id,
            null,
            onComplete
          );
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa, unit]);

  const handleView = (val) => {
    setViewData(val);
    setViewReq(true);
  };

  const handleAddEdit = (val) => {
    setUpdData(val);
    setShowForm(true);
  };

  const handleRevPayment = (data) => {
    setShowRev(true);
    setUpdData(data);
  };

  const ActionComp = (props) => (
    <div className="d-flex a-center">
      <Tooltip arrow title="View Payment">
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          color="primary"
          className="mr-5"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      {!props.isRev ? (
        <Tooltip arrow title="Reverse Payment">
          <IconButton
            onClick={() => handleRevPayment(props)}
            size="small"
            color="error"
            className="mr-5"
          >
            <CurrencyExchangeRounded className="fs-18 m-2" />
          </IconButton>
        </Tooltip>
      ) : (
        <span className="bg-errorSM error-color status">Reversed</span>
      )}
    </div>
  );
  const HeaderComp = () => (
    <div>
      <Button variant="outlined" onClick={() => handleAddEdit()}>
        Add Cash
      </Button>
    </div>
  );

  return (
    <>
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "id", label: "Payment Id" },
          { id: "invId", label: "Invoice Id" },
          { id: "payFrom", label: "Pay From" },
          { id: "ownerName", label: "Owner" },
          { id: "amt", label: "Amount" },
          { id: "refe", label: "Reference" },
          { id: "payDate", label: "Pay Date" },
        ]}
        tblData={pays.map((pay, i) => {
          var payType = PayType.find((j) => j.id === pay.payType);
          return {
            ...pay,
            amt: "$" + pay.amount.toLocaleString(),
            payFrom: payType && payType.label,
            ownerName: pay.owner && pay.owner.fullName,
            payor: pay.payor && pay.payor.title,
            receipt: <span className="tx-upp">{`${pay.emailReceipt}`}</span>,
          };
        })}
      />

      {/* Add Edit Form */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="md">
          <AddEditPay
            {...{
              setShowForm: setShowForm,
              setAlert,
              updData,
              setPays,
              unit,
            }}
          />
        </Dialog>
      )}

      {/* View Requests */}
      <Dialog show={viewReq} onClose={false} maxWidth="md">
        <ViewPayment {...{ setShowForm: setViewReq, viewData }} />
      </Dialog>

      {showRev && (
        <Dialog show={showRev} onClose={false} maxWidth="md">
          <RevForm
            {...{
              setShowForm: setShowRev,
              paymentDtl: updData,
              setPays,
              setAlert,
            }}
          />
        </Dialog>
      )}

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
      <Snackbar {...{ snackbar, setSnackbar }} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Payments);
