import {
  ClearRounded,
  EditRounded,
  PriceCheckRounded,
  VisibilityRounded,
} from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../../controls";
import { Alert, Dialog, Table } from "../../../../layout";
import AddEditPay from "./AddEditPay";
import { connect } from "react-redux";
import * as actions from "../../../../api/actions/actions";
import { AppContext } from "../../../../App";
import { controller, endpoints } from "../../../../api/actions/api";
import ViewAP from "./ViewAP";
import moment from "moment";

const Payables = (props) => {
  const { _getById, _hoa, _delete, vendor, fromHome, _authUser } = props;
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const { setLoader } = useContext(AppContext);
  const [aps, setAps] = useState([]);
  const [updData, setUpdData] = useState();
  const [viewData, setViewData] = useState();
  const [viewAP, setViewAP] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const handleAddEdit = (val) => {
    setUpdData(val);
    setShowForm(true);
  };

  const handleView = (val) => {
    setViewData(val);
    setViewAP(true);
  };

  useEffect(() => {
    const onComplete = (res) => {
      setLoader(false);
      res.status === 200 && setAps(res.result);
    };
    if (vendor) {
      setLoader(true);
      _getById(
        endpoints.AP + controller.GetAllByVendor,
        vendor.id,
        null,
        onComplete
      );
    } else if (_hoa.id) {
      setLoader(true);
      _getById(endpoints.AP + controller.GetByHoa, _hoa.id, null, onComplete);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa, vendor]);

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure!",
      subTitle: "You want to delete this transection record?",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Deleted Successfully",
              subTitle: res.msg,
            });
            setAps((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        _delete(endpoints.AP, id, null, onComplete);
      },
    });
  };

  const handlePaid = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure!",
      subTitle: "You want to mark it as paid.",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAps((x) => [
              ...x.map((j) => (j.id === res.result.id ? res.result : j)),
            ]);
            setAlert({
              isOpen: true,
              type: "success",
              title: "Success",
              subTitle: "Payment status has been updated successfully.",
            });
          } else
            setAlert({
              isOpen: true,
              type: "error",
              title: "Error",
              subTitle: res.msg,
            });
        };
        id &&
          _getById(
            endpoints.AP + controller.MarkComplete,
            id,
            null,
            onComplete
          );
      },
    });
  };

  const ActionComp = (props) => (
    <>
      <Tooltip arrow title="View">
        <IconButton
          onClick={() => handleView(props)}
          size="small"
          color="primary"
          className="btn-error"
        >
          <VisibilityRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      {!props.isComplete && _authUser && _authUser.role !== 1 && (
        <>
          <Tooltip arrow title="Mark As Paid">
            <IconButton
              onClick={() => handlePaid(props.id)}
              size="small"
              color="primary"
              className="mr-5"
            >
              <PriceCheckRounded className="fs-18 m-2" />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Edit Record">
            <IconButton
              onClick={() => handleAddEdit(props)}
              size="small"
              color="primary"
              className="mr-5"
            >
              <EditRounded className="fs-18 m-2" />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Delete Record">
            <IconButton
              onClick={() => handleDlt(props.id)}
              size="small"
              color="error"
            >
              <ClearRounded className="fs-18 m-2" />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );

  const HeaderComp = () => (
    <div className="w-100pr">
      <Button variant="outlined" onClick={() => handleAddEdit()} fullWidth>
        Pay Vendor
      </Button>
    </div>
  );

  return (
    <>
      {!vendor && !fromHome && (
        <div className="mb-15">
          <h2 className="h tx-md fw-5 fs-20 mb-5 tx-upp border-left gray-color">
            Payables
          </h2>
        </div>
      )}
      <Table
        ActionComp={ActionComp}
        HeaderComp={HeaderComp}
        tblHead={[
          { id: "actions", label: "Actions" },
          { id: "status", label: "Status" },
          !vendor && { id: "vendorName", label: "Vendor" },
          { id: "amt", label: "Amount" },
          { id: "txnDate", label: "Transaction Date" },
          { id: "dueDate", label: "Due Date" },
        ]}
        tblData={aps.map((ap) => {
          return {
            ...ap,
            status: ap.isComplete ? (
              <span className="bg-primarySM primary-color status">
                Complete
              </span>
            ) : new Date() >
              new Date(moment(ap.dueDate, "MM/DD/YYYY").add(1, "days")) ? (
              <span className="bg-errorSM error-color status">Overdue</span>
            ) : (
              <span className="bg-warningT warning-color status">Pending</span>
            ),
            vendorName: ap.vendor && ap.vendor.name,
            amt: "$" + ap.amount.toLocaleString(),
          };
        })}
      />

      {/* Add Edit Form */}
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="md">
          <AddEditPay
            {...{ setShowForm: setShowForm, setAlert, updData, setAps, vendor }}
          />
        </Dialog>
      )}

      {/* View Details */}
      <Dialog show={viewAP} onClose={false} maxWidth="md">
        <ViewAP {...{ setShowForm: setViewAP, viewData }} />
      </Dialog>

      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
  _authUser: state.storeData.authUser,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(Payables);
