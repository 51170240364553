import React, { useContext, useEffect, useState } from "react";
import { controller, endpoints } from "../../../api/actions/api";
import * as actions from "../../../api/actions/actions";
import { connect } from "react-redux";
import { AppContext } from "../../../App";
import RepNav from "./RepNav";
import { Table } from "../../../layout";
import moment from "moment";
import { TableCell } from "@mui/material";
import { MultiSelect, Select } from "../../../controls";
import { DropLi } from "../../../hooks";

const ExpByCat = (props) => {
  const { _getById, _hoa } = props;
  const { setLoader } = useContext(AppContext);
  const { PayMethods } = DropLi();
  const [csvHeader, setCsvHeader] = useState([]);
  const [tblData, setTblData] = useState([]);
  const [payments, setPayments] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [byAccVal, setByAccVal] = useState();
  const [byCatVal, setByCatVal] = useState();
  const [byDep, setByDep] = useState([]);
  const [categories, setCategories] = useState([]);
  const [bankAcc, setBankAcc] = useState([]);
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/31/${new Date().getFullYear()}`,
  });

  useEffect(() => {
    setCsvHeader([
      { key: "payDate", label: "Date" },
      { key: "descr", label: "Description" },
      { key: "account", label: "Account" },
      { key: "catName", label: "Category" },
      { key: "amt", label: "Amount" },
    ]);
    return () => {
      setCsvHeader([]);
    };
  }, []);

  useEffect(() => {
    setTblData([
      ...filteredData.map((j) => {
        return {
          ...j,
          descr: j.inv && j.inv.descr,
          account: j.inv && j.inv.bankAcc.accName,
          catName: j.inv.category && j.inv.category.name,
          amt: `$ ${j.amount.toLocaleString()}`,
        };
      }),
    ]);
    return () => {
      setTblData([]);
    };
  }, [filteredData]);

  useEffect(() => {
    if (_hoa.id) {
      _getById(
        endpoints.Payment + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => {
          res.status === 200 && setPayments([...res.result]);
          setLoader(false);
        }
      );
      // Get Revenue Categories
      _getById(endpoints.HoaCat + controller.GetByHoa, _hoa.id, null, (res) => {
        res.status === 200 &&
          setCategories([
            { id: 0, name: "All Category" },
            ...res.result
              .filter((j) => j.catType === 1)
              .map((j) => {
                return {
                  id: j.id,
                  name: j.name,
                };
              }),
          ]);
      });

      // Get Bank Accounts
      _getById(
        endpoints.BankAcc + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => {
          res.status === 200 &&
            setBankAcc([
              { id: 0, name: "All Account" },
              ...res.result.map((j) => {
                return {
                  id: j.id,
                  name: j.accName,
                };
              }),
            ]);
        }
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  useEffect(() => {
    if (payments) {
      var _data = [];
      _data = payments.filter(
        (j) =>
          j.method !== 3 &&
          moment(j.payDate, "MM/DD/YYYY") >=
            moment(sortDate.fromDate, "MM/DD/YYYY") &&
          moment(j.payDate, "MM/DD/YYYY") <=
            moment(sortDate.toDate, "MM/DD/YYYY") &&
          j
      );
      if (byAccVal) _data = _data.filter((j) => j.inv.bankAccId === byAccVal);
      if (byCatVal) _data = _data.filter((j) => j.inv.categoryId === byCatVal);
      if (byDep.length > 0)
        _data = _data.filter((j) => byDep.some((x) => x === j.method));
      setFilteredData(_data);
    }
    return () => {
      setFilteredData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payments, sortDate, byAccVal, byCatVal, byDep]);

  const tblHeader = () => (
    <div className="d-flex a-center flex-wrap w-100pr">
      <Select
        label="Filter By Account"
        size="small"
        value={byAccVal}
        options={bankAcc}
        onChange={(e) => setByAccVal(e.target.value)}
        sx={{
          width: { md: "300px", xs: "100%" },
          mr: { md: "10px", xs: "0px" },
          mb: { md: "0px", xs: "15px" },
        }}
      />
      <Select
        label="Filter By Category"
        size="small"
        value={byCatVal}
        options={categories}
        onChange={(e) => setByCatVal(e.target.value)}
        sx={{
          width: { md: "300px", xs: "100%" },
          mr: { md: "10px", xs: "0px" },
          mb: { md: "0px", xs: "15px" },
        }}
      />
      <MultiSelect
        label="Deposit Type"
        size="small"
        value={byDep}
        onChange={(e) => setByDep(e.target.value)}
        options={PayMethods}
        sx={{ width: { md: "300px", xs: "100%" } }}
      />
    </div>
  );

  return (
    <>
      <div className="mt-20 mb-10">
        <RepNav
          {...{
            csvHeader,
            csvData: tblData,
            sortDate,
            setSortDate,
            title: "Deposit Details",
          }}
        />
      </div>
      <div>
        <Table
          shadow={false}
          ActionComp={() => <></>}
          HeaderComp={tblHeader}
          tblHead={[
            { id: "payDate", label: "Date" },
            { id: "descr", label: "Description" },
            { id: "account", label: "Account" },
            { id: "catName", label: "Category" },
            { id: "amt", label: "Amount" },
          ]}
          tblData={tblData}
          FooterRow={() => (
            <>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                Total Deposits
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell className="gray-color fw-6 fs-italic fs-16">
                $&nbsp;
                {tblData
                  .map((j) => j.amount)
                  .reduce((x, y) => x + y, 0)
                  .toLocaleString()}
              </TableCell>
            </>
          )}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(ExpByCat);
