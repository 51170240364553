import { Box, Divider, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Select } from "../../../controls";
import { DropLi } from "../../../hooks";
import moment from "moment";

const BankAcc = ({ banks, invs }) => {
  const [accounts, setAccounts] = useState([]);
  const [periodic, setPeriodic] = useState(3);
  const [fltInvs, setFltInvs] = useState([]);
  const { PeriodicType } = DropLi();

  useEffect(() => {
    var _accs = [];
    banks.forEach((bank) => {
      var records = fltInvs
        .filter((j) => j.bankAccId === bank.id && j.invType !== 3)
        .map((j) => j.invPayments)
        .flat();
      var deposit = records
        .map(
          (j) =>
            j.paidAmt +
            j.invLateFees.map((j) => j.paidAmt).reduce((a, b) => a + b, 0)
        )
        .reduce((a, b) => a + b, 0);

      deposit && _accs.push({ name: bank.accName, deposit });
    });
    setAccounts([..._accs]);
    return () => {
      setAccounts([]);
    };
  }, [banks, fltInvs]);

  useEffect(() => {
    switch (periodic) {
      case 2:
        setFltInvs([
          ...invs.filter((j) =>
            j.invPayments.some(
              (x) =>
                moment(x.issueDate, "MM/DD/YYYY") >= moment().startOf("week") &&
                moment(x.issueDate, "MM/DD/YYYY") <= moment().endOf("week")
            )
          ),
        ]);
        break;
      case 3:
        setFltInvs([
          ...invs.filter((j) =>
            j.invPayments.some(
              (x) =>
                moment(x.issueDate, "MM/DD/YYYY") >=
                  moment().startOf("month") &&
                moment(x.issueDate, "MM/DD/YYYY") <= moment().endOf("month")
            )
          ),
        ]);
        break;
      case 4:
        setFltInvs([
          ...invs.filter((j) =>
            j.invPayments.some(
              (x) =>
                moment(x.issueDate, "MM/DD/YYYY") >=
                  moment().subtract(1, "month").startOf("month") &&
                moment(x.issueDate, "MM/DD/YYYY") <=
                  moment().subtract(1, "month").endOf("month")
            )
          ),
        ]);
        break;
      case 5:
        setFltInvs([
          ...invs.filter((j) =>
            j.invPayments.some(
              (x) =>
                moment(x.issueDate, "MM/DD/YYYY") >= moment().startOf("year") &&
                moment(x.issueDate, "MM/DD/YYYY") <= moment().endOf("year")
            )
          ),
        ]);
        break;
      case 6:
        setFltInvs([
          ...invs.filter((j) =>
            j.invPayments.some(
              (x) =>
                moment(x.issueDate, "MM/DD/YYYY") >=
                  moment().subtract(1, "year").startOf("year") &&
                moment(x.issueDate, "MM/DD/YYYY") <=
                  moment().subtract(1, "year").endOf("year")
            )
          ),
        ]);
        break;

      default:
        setFltInvs([
          ...invs.filter((j) =>
            j.invPayments.some(
              (x) =>
                moment(x.issueDate, "MM/DD/YYYY").format("MM/DD/YYYY") ===
                moment().format("MM/DD/YYYY")
            )
          ),
        ]);

        break;
    }
    return () => {
      setFltInvs([]);
    };
  }, [periodic, invs]);

  const handleInpChange = (e) => {
    setPeriodic(e.target.value);
  };

  return (
    <Paper>
      <div className="p-10 d-flex a-center j-between">
        <span className="h d-flex a-center fs-18 fw-5 gray-color">
          Bank Deposit
        </span>
        <Select
          size="small"
          variant="outlined"
          onChange={handleInpChange}
          value={periodic}
          options={PeriodicType}
        />
      </div>
      <Divider />
      <div className="d-flex j-between p-10">
        <span className="gray-color tx-upp">Total:</span>
        <span className="fw-5">
          $&nbsp;
          {accounts
            .map((j) => j.deposit)
            .reduce((a, b) => a + b, 0)
            .toLocaleString()}
        </span>
      </div>
      {accounts.length > 0 && <Divider />}
      <Box sx={{ maxHeight: "470px", overflow: "auto" }}>
        {accounts.map((acc, i) => (
          <div key={i} className="mb-15 bs-secondary m-10">
            <div className="d-flex j-between p-10">
              <span className="fs-18 fw-6 gray-color">{acc.name} :</span>
              <span className="fs-18 fw-6 gray-color">
                $&nbsp;{acc.deposit.toLocaleString()}
              </span>
            </div>
          </div>
        ))}
      </Box>
    </Paper>
  );
};

export default BankAcc;
