import { Box, Grid, IconButton, TableCell, Tooltip } from "@mui/material";
import React, { useContext } from "react";
import { Alert, Dialog, Table } from "../../../../layout";
import { connect } from "react-redux";
import { useEffect } from "react";
import { AppContext } from "../../../../App";
import { controller, endpoints } from "../../../../api/actions/api";
import * as actions from "../../../../api/actions/actions";
import { useState } from "react";
import RepNav from "../RepNav";
import moment from "moment";
import { Button, MultiSelect } from "../../../../controls";
import AddEditRcd from "./AddEditRcd";
import { ClearRounded, EditRounded } from "@mui/icons-material";

const BankReco = (props) => {
  const { _getById, _hoa, _delete } = props;
  const [csvData, setCsvData] = useState([]);
  const [fltSystem, setFltSystem] = useState([]);
  const [fltBankRcd, setFltBankRcd] = useState([]);
  const [updData, setUpdData] = useState();
  const [showForm, setShowForm] = useState(false);
  const [tblHead, setTblHead] = useState([]);
  const [systemRcd, setSystemRcd] = useState([]);
  const [bankRcd, setBankRcd] = useState([]);
  const [ap, setAp] = useState([]);
  const [ar, setAr] = useState([]);
  const [fltByBank, setFltByBank] = useState([]);
  const [banks, setBanks] = useState([]);
  const [sortDate, setSortDate] = useState({
    fromDate: `1/1/${new Date().getFullYear()}`,
    toDate: `12/1/${new Date().getFullYear()}`,
  });
  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    title: "",
    subTitle: "",
  });
  const { setLoader } = useContext(AppContext);

  useEffect(() => {
    setTblHead([
      { label: "Date", id: "date" },
      { label: "Bank", id: "bank" },
      { label: "Amount", id: "amt" },
    ]);

    return () => {};
  }, []);

  useEffect(() => {
    setCsvData([
      {},
      { txnId: "BANK RECORDS" },
      ...fltBankRcd,
      {},
      { txnId: "SYSTEM RECORDS" },
      ...fltSystem,
    ]);
    return () => {
      setCsvData([]);
    };
  }, [fltBankRcd, fltSystem]);

  useEffect(() => {
    if (systemRcd) {
      var _fltData = [];
      _fltData = systemRcd.filter(
        (j) =>
          moment(j.date, "MM/DD/YYYY") >=
            moment(sortDate.fromDate, "MM/DD/YYYY") &&
          moment(j.date, "MM/DD/YYYY") <= moment(sortDate.toDate, "MM/DD/YYYY")
      );

      if (fltByBank.length > 0)
        _fltData = _fltData.filter((j) =>
          fltByBank.some((x) => x === j.bankId)
        );

      setFltSystem([
        ..._fltData.sort((a, b) => new Date(b.date) - new Date(a.date)),
      ]);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemRcd, sortDate, fltByBank]);

  useEffect(() => {
    if (bankRcd) {
      var _fltData = [];
      _fltData = bankRcd.filter(
        (j) =>
          moment(j.date, "MM/DD/YYYY") >=
            moment(sortDate.fromDate, "MM/DD/YYYY") &&
          moment(j.date, "MM/DD/YYYY") <= moment(sortDate.toDate, "MM/DD/YYYY")
      );

      if (fltByBank.length > 0)
        _fltData = _fltData.filter((j) =>
          fltByBank.some((x) => x === j.bankId)
        );

      setFltBankRcd([
        ..._fltData.sort((a, b) => new Date(b.date) - new Date(a.date)),
      ]);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankRcd, sortDate, fltByBank]);

  useEffect(() => {
    setSystemRcd([
      ...ap.map((j) => ({
        date: j.paidDate,
        amt: -j.amount,
        bankId: j.bankAcc.id,
        bank: j.bankAcc.accName,
      })),
      ...ar.map((j) => ({
        date: j.payDate,
        amt: j.amount,
        bankId: j.inv && j.inv.bankAcc.id,
        bank: j.inv && j.inv.bankAcc.accName,
      })),
    ]);

    return () => {
      setSystemRcd([]);
    };
  }, [ap, ar]);

  useEffect(() => {
    if (_hoa.id) {
      setLoader(true);
      _getById(endpoints.AP + controller.GetByHoa, _hoa.id, null, (res) => {
        setLoader(false);
        res.status === 200 &&
          setAp([...res.result.filter((j) => j.isComplete)]);
      });

      _getById(
        endpoints.Payment + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => {
          res.status === 200 &&
            setAr((x) => [...res.result.filter((j) => j.method !== 3)]);
        }
      );

      _getById(
        endpoints.BankReco + controller.GetByHoa,
        _hoa.id,
        null,
        (res) => {
          if (res.status === 200) {
            setBankRcd([
              ...res.result.map((j) => ({
                ...j,
                txnId: j.txnId,
                date: j.payDate,
                amt: j.txnType === 1 ? j.amount : -j.amount,
                bank: j.bank.accName,
              })),
            ]);
          }
        }
      );

      _getById(
        endpoints.BankAcc + controller.GetByHoa,
        _hoa.id,
        null,
        (res) =>
          res.status === 200 &&
          setBanks([...res.result.map((j) => ({ id: j.id, name: j.accName }))])
      );
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_hoa]);

  const handleAddEdit = (val) => {
    setUpdData(val);
    setShowForm(true);
  };

  const handleDlt = (id) => {
    setAlert({
      isOpen: true,
      type: "confirmation",
      title: "Are you sure",
      subTitle: "You want to delete this record?",
      onConfirm: () => {
        const onComplete = (res) => {
          if (res.status === 200) {
            setAlert({
              isOpen: true,
              type: "success",
              title: "Deleted Successfully",
              subTitle: res.msg,
            });
            setBankRcd((x) => [...x.filter((j) => j.id !== res.result.id)]);
          } else {
            setAlert({
              isOpen: true,
              type: "warning",
              title: "Warning",
              subTitle: res.msg,
            });
          }
        };
        _delete(endpoints.Request, id, null, onComplete);
      },
    });
  };

  const ActionComp = (props) => (
    <>
      <Tooltip arrow title="Edit Record">
        <IconButton
          onClick={() => handleAddEdit(props)}
          size="small"
          color="primary"
          className="mr-5"
        >
          <EditRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
      <Tooltip arrow title="Delete Record">
        <IconButton
          onClick={() => handleDlt(props.id)}
          size="small"
          color="error"
        >
          <ClearRounded className="fs-18 m-2" />
        </IconButton>
      </Tooltip>
    </>
  );

  const IncHeader = () => (
    <div className="d-flex a-center flex-wrap">
      <h2 className="border-left h fw-4 fs-16 gray-color tx-upp">
        System Records
      </h2>
    </div>
  );
  const ExpHeader = () => (
    <Box className="d-flex a-center flex-wrap w-100pr j-between">
      <Box sx={{ mr: "15px" }}>
        <h2 className="border-left h fw-4 fs-16 gray-color tx-upp">
          Bank Records
        </h2>
      </Box>

      <Button size="small" variant="outlined" onClick={() => handleAddEdit()}>
        Add Record
      </Button>
    </Box>
  );
  return (
    <>
      <Grid container spacing={2} className="pt-15">
        <Grid item xs={12}>
          <RepNav
            {...{
              csvHeader: [
                { key: "txnId", label: "Txn Id" },
                ...tblHead.map((j) => ({ key: j.id, ...j })),
              ],
              csvData,
              sortDate,
              setSortDate,
              title: "Bank Reconciliation",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="d-flex j-end">
            <MultiSelect
              label="Filter By Bank"
              size="small"
              value={fltByBank}
              onChange={(e) => setFltByBank(e.target.value)}
              options={banks}
              sx={{ width: { md: "300px", xs: "100%" } }}
            />
          </div>
        </Grid>

        {/* System Records  */}
        <Grid item xs={12} md={5}>
          <Table
            shadow={false}
            showSearch={false}
            ActionComp={() => <></>}
            HeaderComp={IncHeader}
            tblHead={tblHead}
            tblData={fltSystem.map((j) => ({
              ...j,
              amt: (
                <span className={j.amt < 0 ? "error-color" : ""}>
                  {j.amt.toLocaleString()}
                </span>
              ),
            }))}
            FooterRow={() => (
              <>
                <TableCell className="gray-color fw-6 fs-italic fs-16">
                  Total
                </TableCell>
                <TableCell></TableCell>
                <TableCell className="gray-color fw-6 fs-italic fs-16">
                  {fltSystem
                    .map((j) => j.amt)
                    .reduce((a, b) => a + b, 0)
                    .toLocaleString()}
                </TableCell>
              </>
            )}
          />
        </Grid>

        {/* Bank Record */}
        <Grid item xs={12} md={7}>
          <Table
            shadow={false}
            showSearch={false}
            ActionComp={ActionComp}
            HeaderComp={ExpHeader}
            tblHead={[
              { label: "Actions", id: "actions" },
              { label: "Txn Id", id: "txnId" },
              ...tblHead,
            ]}
            tblData={fltBankRcd.map((j) => ({
              ...j,
              amt: (
                <span className={j.amt < 0 ? "error-color" : ""}>
                  {j.amt.toLocaleString()}
                </span>
              ),
            }))}
            FooterRow={() => (
              <>
                <TableCell className="gray-color fw-6 fs-italic fs-16">
                  Total
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell className="gray-color fw-6 fs-italic fs-16">
                  {fltBankRcd
                    .map((j) => j.amt)
                    .reduce((a, b) => a + b, 0)
                    .toLocaleString()}
                </TableCell>
              </>
            )}
          />
        </Grid>
      </Grid>
      {showForm && (
        <Dialog show={showForm} onClose={false} maxWidth="md">
          <AddEditRcd
            {...{
              setShowForm,
              setAlert,
              setList: setFltBankRcd,
              updData,
              banks,
            }}
          />
        </Dialog>
      )}
      {/* Alert  */}
      <Alert alert={alert} setAlert={setAlert} />
    </>
  );
};

const mapStateToProps = (state) => ({
  _hoa: state.storeData.defHoa,
});

const mapActionToProps = {
  _getById: actions.getById,
  _delete: actions.Delete,
};

export default connect(mapStateToProps, mapActionToProps)(BankReco);
